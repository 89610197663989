import React from 'react'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import GoedDoel from './goed_doel_card'
import creds from './sheets.json'

//console.log('ENV:', process.env.REACT_APP_SPREADSHEET_ID)
class FormKerst extends React.Component {
    constructor(props) {
        super()
        this.state = {
            email: '',
            goed_doel: '',
            error_message: false,
            submitted: false
        }
    }

    handleChange(event) {
        const target = event.target
        const value = target.value
        const key = target.name
        this.setState({ [key]: value })
    }

    appendSpreadsheet = async row => {
        console.log('Sturen:', row)
        const doc = new GoogleSpreadsheet(process.env.REACT_APP_SPREADSHEET_ID)
        console.log('Creds', creds)
        try {
            await doc.useServiceAccountAuth(creds)
            console.log(doc)
            // loads document properties and worksheets
            await doc.loadInfo()
            console.log('Doc:', doc)
            const sheet = doc.sheetsById[process.env.REACT_APP_SHEET_ID]
            console.log('Sheet:', sheet)
            await sheet.addRow(row)
        } catch (e) {
            console.error('Error: ', e)
        }
    }

    handleSubmit() {
        if (this.state.email === '' || this.state.goed_doel === '') {
            console.log('invalid')
            this.setState({ error_message: true })
        } else {
            const newRow = {
                email: this.state.email,
                goed_doel: this.state.goed_doel
            }
            this.appendSpreadsheet(newRow)
            this.setState({ submitted: true })
        }
    }
    render() {
        return (
            <div className="formDiv">
                <div className="goede_doelen">
                    <div className="goede_doelen__title">
                        Doneer op onze kosten aan een goed doel uit onderstaande
                        lijst.
                    </div>
                    <div className="goede_doelen__subtitle">
                        Selecteer het doel van je keuze, vul onderaan je
                        emailadres in en wij zorgen voor de donatie!
                    </div>
                    <div className="goede_doelen__cardcontainer">
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./kika-logo.png"
                            value="Kika"
                            text="Vroeg of laat krijgen we allemaal te maken krijgen met kanker. Wanneer het een kind betreft, is het echter niet te bevatten wat voor impact dit heeft op het kind zelf, de omgeving, de ouders en de toekomst. Hopelijk zijn we het erover eens dat een hogere overlevingskans voor zulke jonge mensen met een heel leven voor zich essentieel is! Wat mij betreft is Kika een waardevol initiatief om uiteindelijk kids te helpen die weer naar school kunnen, kunnen spelen en dromen over de toekomst. Vandaar dat ik graag een bijdrage voor Kika vraag."
                            selected=""
                            link="https://kika.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./savethechildren-logo.png"
                            value="Save the Children (Hulp voor kinderen in Midden-Oosten)"
                            text="Save the Children zet zich in voor de veiligheid van kinderen, regelmatig met gevaar voor eigen leven. Ze proberen kinderen te voorzien van eten, drinken, medicatie, slachtofferhulp en veiligheid. Bizar dat zoiets nodig is, dat kunnen wij ons hier bijna niet voorstellen. Op dit moment zijn ze met name actief in het midden oosten, waar de situatie voor kinderen levensgevaarlijk is, en dat raakt me. Elke ouder wil het beste voor zijn of haar kind. Wij hebben het hier goed, en dat gun ik andere kinderen (en ouders ook). Kinderen zijn onze toekomstige artsen, brandweermannen en wereldverbeteraars en elke euro die in hen geïnvesteerd kan worden, zal zich dubbel en dwars terugbetalen!"
                            selected=""
                            link="https://www.savethechildren.nl/pagina/gaza-israel-conflict "
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./stichtinglongcovid-logo.png"
                            value="Stichting Longcovid "
                            text="Binnen LevelUp Group hadden we meerdere collega’s met serieuze Long Covid klachten. Allen behalve één zijn hiervan gelukkig hersteld. De ene collega die niet hersteld is, was dusdanig vermoeid dat hij geheel gestopt is met werken. Zo zijn er in Nederland nog bijna 100.000 mensen die dagelijks worstelen met de gevolgen van Long Covid. Er is echter nog weinig bekend over de genezing van Long Covid en daar helpt de Stichting Longcovid bij door geld in te zamelen voor onderzoek naar deze aandoening. De eerste onderzoeken zijn al gestart maar meer geld voor onderzoeken is nodig!"
                            selected=""
                            link="https://www.stichtinglongcovid.nl/  "
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./100weeks-logo.png"
                            value="100WEEKS"
                            text="Door vrouwen in Afrika gedurende 100 weken 8 euro per week te geven in combinatie met financiële coaching, helpt 100WEEKS hen om voorgoed uit de armoede te kunnen komen. Ze hebben hiermee al meer dan 3.000 vrouwen een mooie toekomst kunnen bieden. De methode van 100WEEKS is eenvoudig en bewezen effectief. De 100 weken financiële support geeft de vrouwen de kans om op eigen wijze uit de armoede te komen. Doordat ze niet meer elke dag hoeven te focussen op overleven, wordt het mogelijk naar de toekomst te kijken. Juist omdat ontvangers weten dat er aan de wekelijkse betalingen een einde komt, grijpen ze deze kans aan om te investeren in de toekomst. De deelnemers bepalen zelf hoe ze het geld besteden. 100WEEKS biedt deze vrouwen financiële coaching en training in ondernemersvaardigheden. Ook komen de vrouwen in het programma samen in spaargroepen. Dit Nederlandse goede doel verdient onze steun omdat het bewezen effectief is in armoedebestrijding!"
                            selected=""
                            link="https://100weeks.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./legerdesheils-logo.png"
                            value="Leger des Heils"
                            text="Het Leger des Heils biedt hulp aan mensen in nood en in kwetsbare situaties. Dit doen ze op diverse manieren. Zo zorgen ze voor de opvang en ondersteuning aan daklozen en verslaafden. Daarnaast helpen ze mensen in armoede door het verstrekken van voedsel, kleding en andere basisbehoeften aan mensen in armoede. Ook biedt Het Leger des Heils biedt maatschappelijke dienstverlening, waaronder counseling, hulp bij schulden, gezinsbegeleiding, reclassering, jeugdhulp en buurthulp. In 2022 zijn 114.636 mensen door het Leger des Heils ondersteund waarbij 3,6 miljoen overnachtingen zijn verzorgd voor daklozen en thuislozen waaronder veel Oekraïense vluchtelingen. Verder zijn 11,1 miljoen maaltijden verstrekt en hebben 11.400 vrijwilligers zich ingezet. De waardevolle activiteiten van het Leger des Heils verdienen onze steun dus help hen om dit goede werk voor te zetten!"
                            selected=""
                            link="https://www.legerdesheils.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./oxfamnovib-logo2.jpg"
                            value="Oxfam Novib"
                            text="Oxfam Novib zet zich in voor armoedebestrijding en sociale rechtvaardigheid door bieden van ontwikkelingssamenwerkingen humanitaire hulp. Dit goede doel staat achter de mensen die opstaan voor gelijkheid. Mensen die op hun eigen manier strijden tegen de groeiende verschillen. Tegen onrecht. Tegen klimaatverandering, dat de meest kwetsbaren het hardst raakt. Mensen die opkomen voor eerlijke lonen. Voor het recht zichzelf te kunnen zijn. <br /> Oxfam Novib doet dit door te werken aan een wereld die inclusief, veilig, rechtvaardig en duurzaam is. Een wereld die voor iedereen veilig is; waarin de inkomenskloof krimpt in plaats van groeit; waar klimaatverandering harder en eerlijker wordt aangepakt. Sta op en doe mee. Samen strijden we voor gelijkheid. Door Oxfam Novib te steunen help je de mensen die strijden voor gelijke rechten voor iedereen. "
                            selected=""
                            link="https://www.oxfamnovib.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./worldvision-logo.png"
                            value="World Vision"
                            text="World Vision zet zich in voor het welzijn van kinderen in ontwikkelingslanden door het verbeteren van onderwijs, gezondheidszorg, voeding en bescherming tegen misbruik en uitbuiting. World Vision streeft naar duurzame ontwikkeling door het ondersteunen van gemeenschappen bij het opbouwen van hun eigen capaciteiten en veerkracht. Ze helpt waar kinderen en families in nood zijn. In ontwikkelingslanden komt de bevolking veel te kort: water, voedsel, medische zorg, opleiding en inkomen. Er zijn speciale kindsponsoringprogramma’s waarbij je als sponsor kan schrijven en e-mailen naar jouw sponsorkind. Je ontvangt dan foto’s van jouw sponsorkind en van de gemeenschap waar hij/zij woont. Wereldwijd worden er via World Vision maar liefst 3,2 miljoen kinderen gesponsord! Gun kinderen in ontwikkelingslanden ook een mooier leven en steun World Vision!"
                            selected=""
                            link="https://www.worldvision.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./hartstichting.svg"
                            value="Hartstichting"
                            text="Er zijn nu 1,7 miljoen hart- en vaatpatiënten in Nederland. En dat aantal neemt sterk toe. Het doel van de Hartstichting is om het aantal mensen dat getroffen wordt door hart- en vaatziekten te verminderen, de overlevingskansen te vergroten en de kwaliteit van leven van mensen met hartproblemen te verbeteren. Door wetenschappelijk onderzoek dat de Hartstichting ondersteunt, komen er betere preventiemethoden en behandelingen. Daarnaast wil de Hartstichting door het geven van informatie over een gezonde levensstijl hart- en vaatziekten beter voorkomen. De informatie omvat educatieve programma's, voorlichtingsmateriaal en campagnes om mensen bewust te maken van risicofactoren en symptomen. Help mee aan een gezonder Nederland en kies voor de Hartstichting!"
                            selected=""
                            link="https://www.hartstichting.nl/over-ons"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./amnesty-logo.svg"
                            value="Amnesty International"
                            text="&lsquo;Pas als de laatste gewetensgevangene is vrijgelaten, als de laatste martelkamer is gesloten, als de Universele Verklaring van de Rechten van de Mens een realiteit is voor de wereldbevolking, zit ons werk erop.&rsquo; Dat zijn de woorden van Peter Benenson, oprichter van Amnesty International. Deze een wereldwijde mensenrechtenorganisatie zet zich in voor de bevordering en verdediging van mensenrechten over de hele wereld. Belangrijke kwesties waar ze zich op richt zijn strijden voor de rechten van politieke gevangenen, afschaffing van de doodstraf, opkomen voor de vrijheid van meningsuiting en strijden tegen politiek geweld. Dit doet ze door het onderzoeken en aan de kaak stellen van mensenrechtenschendingen, het indienen van juridische petities, het ondersteunen van juridische zaken, het voeren campagnes om bewustwording te creëren over specifieke kwesties door te lobbyen en diplomatie met overheden en internationale organisaties. Stop onrecht en steun Amnesty."
                            selected=""
                            link="https://www.amnesty.nl/wie-we-zijn"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./natuurmonumenten-logo.svg"
                            value="Natuurmonumenten"
                            text="Al een paar jaar ben ik zelf donateur van Natuurmonumenten omdat ik vind dat ze heel waardevol en mooi werk doen. Natuurmonumenten focust zich op het herstel van de biodiversiteit: zowel van het aantal soorten planten en dieren, als van de hoeveelheid per soort. In samenwerking met boeren, bedrijven en burgers werken ze aan duurzame oplossingen die de biodiversiteit versterken. En herstel van de biodiversiteit is hard nodig. In nog geen 30 jaar is bijvoorbeeld maar liefst 76% van de insecten in Nederland verdwenen. De natuur is belangrijk voor onze gezondheid en bestaanszekerheid en daarom vind ik dat we ons daarvoor moeten inzetten."
                            selected=""
                            link="https://www.natuurmonumenten.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./freeagirl.png"
                            value="Free a Girl"
                            text="Als ik &eacute;&eacute;n wereldprobleem was dat ik op kon lossen, dan zou dat mensenhandel zijn. Zeker rondom kinderen. Het gaat me echt aan het hart dat er elke dag kinderen van hun leven worden beroofd, zonder daadwerkelijk te sterven. Maar de omstandigheden waarin zij terecht komen, zijn vaak zo erg dat hun levenslust snel wegvloeit. Ik kan me als jonge vrouw in deze maatschappij gelukkig totaal niet voorstellen hoe het voelt om mee te maken wat de meisjes meemaken die Free a Girl dag in dag uit probeert te bevrijden. Maar omdat ik juist wel weet hoe het voelt om vrij te zijn en een onbezonnen jeugd te hebben, gun ik dat elk kind. Free a Girl bestrijdt seksuele uitbuiting van kinderen. Dit doen ze onder o.a. door wereldwijd activistische organisaties te ondersteunen die er alles aan doen om kinderen te bevrijden uit situaties waarin zij seksueel worden uitgebuit. Ook pakken ze straffeloosheid aan door juridische ondersteuning te verzorgen en bieden ze slachtoffers de nodige medische/ mentale nazorg. Met als doel dat uiteindelijk álle kinderen van een vrije en onbezonnen jeugd kunnen genieten."
                            selected=""
                            link="https://www.freeagirl.nl/"
                        />
                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./jarigejob.svg"
                            value="Stichting Jarige Job"
                            text="Voor veel kinderen is een verjaardag met cadeaus, visite en trakteren op school vanzelfsprekend. Voor tienduizenden kinderen in Nederland is dit niet het geval. Stichting Jarige Job helpt deze kinderen door het geven van een verjaardag box t.w.v. €35,-. Hierin zit alles wat nodig is voor een echte verjaardag, thuis én op school! Jaarlijks vieren inmiddels meer dan 100.000 kinderen hun verjaardag met de hulp van Jarige Job! Ik vind dat ieder kind een verjaardag verdient!"
                            selected=""
                            link="https://stichtingjarigejob.nl/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./stichting-als.svg"
                            value="Stichting ALS Nederland"
                            text="Ik heb helaas van dichtbij meegemaakt hoe snel de ziekte ALS zich ontwikkelt, waardoor verder onderzoek heel sterk wenselijk is. Stichting ALS Nederland brengt mensen en partijen samen om zoveel mogelijk geld op te halen voor wetenschappelijk onderzoek naar de oorzaak en behandeling van ALS en de aanverwante ziekten PSMA en PLS. Daarnaast financiert Stichting ALS projecten voor een betere kwaliteit van zorg en leven voor patiënten en hun naasten."
                            selected=""
                            link="https://www.als.nl/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./staatsbosbeheer.svg"
                            value="Buitenfonds van Staatsbosbeheer"
                            text="Het Buitenfonds van onze klant Staatsbosbeheer zamelt geld in voor diverse groene projecten. Een project waar we graag aan willen bijdragen is om landbouwgrond bij de Strijbeekse Heide (Noord-Brabant) aan te kopen en om te vormen naar bosrijke natuur."
                            selected=""
                            link="https://www.buitenfonds.nl/crowdfunding/projecten/project/nieuwe_natuur"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./met-je-hart-logo.jpg"
                            value="Stichting met je hart"
                            text="Sinds een jaar ben ik als chauffeur betrokken bij de “Stichting met je hart”, een landelijke vrijwilligersorganisatie met de simpele missie: Kwetsbare mensen verbinden. Dat doen ze door waardevolle terugkerende ontmoetingen te organiseren voor ouderen die veelal in onzichtbaarheid gevoelens van eenzaamheid ervaren. Het is echt hartverwarmend om deze mensen een warme dag te bezorgen. Daarom wil ik ze graag aandragen als goed doel."
                            selected=""
                            link="https://www.metjehart.nl/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./seaturtleconservancy.png"
                            value="Sea Turtle Conservation Bonaire"
                            text="De Sea Turtle Conservation in Bonaire ondersteunt inspanningen op het gebied van onderzoek, monitoring en educatie voor en over schildpadden draagt bij aan de bescherming van zeeschildpadden en hun leefomgeving op Bonaire. Het is mooi als de schildpadden behouden kunnen blijven."
                            selected=""
                            link="https://www.bonaireturtles.org/wp/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./epilepsie-logo.svg"
                            value="Epilepsie.nl"
                            text="Epilepsie verstoort de levens van 200.000 mensen in Nederland. Tijdens het boodschappen doen, klussen of onderweg naar je werk. Voor sommigen iedere dag, voor anderen eens per jaar. Maar altijd onverwacht. En het kan ieder van ons overkomen. Mij, jou, je partner, je ouders of je kind, in elke fase van het leven. Soms met een duidelijke oorzaak, zoals een ongeluk of een hersenbloeding, vaker zonder duidelijke aanleiding. Epilepsie.nl zorgt voor onderzoek naar betere medicijnen en nieuwe behandelingen, zodat ze iedereen met epilepsie een beter leven kunnen geven."
                            selected=""
                            link="https://www.epilepsie.nl/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./doneer-effectief.webp"
                            value="Armoede en Gezondheid Fonds van Doneer Effectief"
                            text="Armoede zie ik als een van de grootste problemen wereldwijd. De gevolgen zijn enorm: honger, ziektes, (kinder)sterfte. Bovendien is de gezondheidszorg in landen met veel armoede moeilijker te bereiken en van lagere kwaliteit. Armoede de wereld uit helpen is momenteel dan ook een van de meest urgente mondiale doelstellingen. Het gerenommeerde instituut GiveWell onderzoekt welke goede doelen per gedoneerde euro de meeste levens redden of verbeteren. Sommige blijken daarin wel honderd keer effectiever dan andere! De selectie van goede doelen in het Armoede en Gezondheid fonds van Doneer Effectief is gebaseerd op die belangrijke informatie. Met onze donatie kunnen we hier bewezen de meeste levens redden."
                            selected=""
                            link="https://doneereffectief.nl/toporganisaties/armoede/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./opportunity-green-logo.png"
                            value="Opportunity Green"
                            text="Opportunity Green is een goed doel dat zich inzet voor gedurfde en impactvolle klimaatoplossingen, met een focus op gelijkheid, rechtvaardigheid en verantwoordelijkheid. Het team van Opportunity Green bestaat uit experts op het gebied van relevante wetgeving, beleid en economie in de industrieën die ze willen aanpakken. Hun innovatieve aanpak bestaat uit drie strategieën: het verenigen van progressieve bedrijven om de ambities van overheden te verhogen, het ondersteunen van klimaatkwetsbare landen en het ondernemen van juridische stappen tegen achterblijvende klimaatactie."
                            selected=""
                            link="https://www.opportunitygreen.org/"
                        />

                        <GoedDoel
                            handleChange={this.handleChange.bind(this)}
                            src="./tjommie-logo.svg"
                            value="Tjommie"
                            text="In Zuid-Afrika betekent Tjommie ‘vriend’. Tjommie is goed doel dat al 20 jaar kinderen die opgroeien in townships van Zuid-Afrika de steun biedt die ze zo hard nodig hebben. Deze kinderen staan dagelijks bloot aan geweld, misbruik, drugs, werkloosheid en jeugdzwangerschappen. Een vriend die hulp biedt, is daarom van levensbelang. Dit doet Tjommie door te voorzien in hun basisbehoeften. Daarnaast helpt Tjommie door jongeren te stimuleren hun talenten te ontdekken en te ontwikkelen. Hierdoor neemt zowel hun eigenwaarde als zelfvertrouwen toe en kunnen ze hun zorgen even helemaal vergeten. Dat doet Tjommie onder andere door activiteiten te organiseren die zich richten op muziek, drama en sport. Dit heeft bijvoorbeeld geresulteerd in het opzetten van het Ndlovu Youth Choir dat zelfs deelnam aan de finale van America’s Got Talent. Steun Tjommie met haar goede werk!"
                            selected=""
                            link="https://www.tjommie.nl/"
                        />
                    </div>
                </div>
                {!this.state.submitted && (
                    <div className="address">
                        {this.state.error_message && (
                            <div className="error">
                                Vul a.u.b. je emailadres in en selecteer een
                                goed doel!
                            </div>
                        )}
                        <label>Je emailadres (voor bevestiging)</label>

                        <div className="address__input_container">
                            <input
                                className="input email"
                                type="text"
                                name="email"
                                required="required"
                                onChange={this.handleChange.bind(this)}
                            ></input>

                            <input
                                className="button green submit"
                                type="submit"
                                value="Stem"
                                onClick={this.handleSubmit.bind(this)}
                            ></input>
                        </div>
                    </div>
                )}
                {this.state.submitted && (
                    <div className="goede_doelen__subtitle succes">
                        Dank voor het doorgeven van jouw voorkeur.
                    </div>
                )}
            </div>
        )
    }
}

export default FormKerst
